
.rc-pagination {
    display: flex;
    gap: 8px;
  }
  
  .rc-pagination-item {
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    cursor: pointer;
  }
  
  .rc-pagination-item-active {
    background: linear-gradient(180deg, #0EA5E9 0%, #2563EB 100%);
    border: 1px solid rgba(14, 165, 233, 1);
    box-shadow: 0px 4px 24px 4px rgba(37, 102, 236, 0.2);
  }
  
  .rc-pagination-disabled {
    cursor: not-allowed;
    color: rgba(156, 163, 175, 1);
  }
  
  .rc-pagination-prev {
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    cursor: pointer;
  }
  
  .rc-pagination-next {
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    cursor: pointer;
  }
  
  .rc-pagination-jump-next {
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    cursor: pointer;
  }
  
  .rc-pagination-jump-prev {
    padding: 10px 16px 10px 16px;
    border-radius: 4px;
    border: 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 100%);
    border: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    cursor: pointer;
  }
  
  @media (max-width: 991px) {
    .rc-pagination-item {
      padding: 10px 12px;
    }
  
    .rc-pagination-prev {
      padding: 10px 12px;
    }
  
    .rc-pagination-next {
      padding: 10px 12px;
    }
  
    .rc-pagination-jump-next {
      padding: 10px 12px;
    }
  
    .rc-pagination-jump-prev {
      padding: 10px 12px;
    }
  }